<template>
  <div class="cropper" :class="{ 'cropper--square': square }">
    <div class="cropper__wrapper">
      <input
        type="file"
        ref="file"
        @change="uploadImage($event)"
        accept="image/*"
      />
      <div class="cropper__info">
        <p>Загрузите вашу фотографию</p>
        <ul>
          <li>
            Вы можете загрузить изображение в формате JPG, PNG, BMP не более 2
            МБ
          </li>
          <li>
            Изображение должно быть четкое<span v-if="!square">, положение фотографируемого в анфас</span>
          </li>
          <li>Изображение на фотографии — цветное</li>
          <li v-if="!square">
            Количество человек изображенных на фотографии не должно быть более
            одного
          </li>
          <li v-if="!square">На фотографии не должны присутствовать иные предметы</li>
        </ul>
      </div>
      <div
        class="cropper__add"
        @click="$refs.file.click()"
        v-show="!croppingImg"
      >
        <div class="cropper__part-top"></div>
        <div class="cropper__part-bottom">Добавить фото</div>
      </div>
      <div class="cropper__photo" v-show="croppingImg">
        <div class="cropper__result">
          <img :src="croppingImg" alt=""/>
        </div>
        <div class="cropper__fade">
          <button type="button" class="cropper__delete" @click="reset">
            <img src="../assets/img/cropper/delete-cropper.svg" alt=""/>
          </button>
          <div class="cropper__controls">
            <button type="button" @click="triggerFileClick">
              Добавить другое фото
            </button>
            <button type="button" @click="showModal">Изменить миниатюру</button>
          </div>
        </div>
      </div>
      <modal
        name="modal"
        :width="900"
        :height="'auto'"
        :scrollable="true"
        :adaptive="true"
      >
        <div class="modal">
          <div class="modal__title">Загрузка фотографии</div>
          <div class="modal__body">
            <div class="modal__content">
              <div class="modal__picture">
                <cropper
                  :src="originalCropImg"
                  ref="cropper"
                  :stencil-props="stencilProps"
                />
              </div>
              <div class="modal__footer">
                <button
                  type="button"
                  class="button button--transparent"
                  @click="$refs.file.click()"
                >
                  <img src="@/assets/img/cropper/camera-icon.svg" alt="">
                  <span>Загрузить другую фотографию</span>
                </button>
                <button
                  type="button"
                  class="button button--default"
                  @click="crop"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <div class="cropper__message" v-if="!isImageFlag">
      Выберите правильный формат файла, принимаются файлы <b>JPG, PNG, BMP</b>
    </div>
    <div class="cropper__message" v-if="!isSizeFlag">
      Размер файла не должен привышать <b>2 мб</b>
    </div>
  </div>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';

export default {
  name: 'CropperModal',
  components: {
    Cropper
  },
  props: ['getCropCoords', 'getCropHash', 'originalImgUrlProp', 'croppedImgUrlProp', 'stencilProps', 'square'],
  data() {
    return {
      originalCropImg: null,
      croppingImg: null,
      isImageFlag: true,
      isSizeFlag: true,
    };
  },
  watch: {
    originalImgUrlProp() {
      this.originalCropImg = this.originalImgUrlProp;
    },
    croppedImgUrlProp() {
      this.croppingImg = this.croppedImgUrlProp;
    }
  },
  methods: {
    showModal() {
      this.$modal.show('modal');
    },
    hideModal() {
      this.$modal.hide('modal');
    },
    reset() {
      this.originalCropImg = null;
      this.croppingImg = null;
      this.input = null;
      this.$emit('clearPhoto');
    },
    getExtension(filename) {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    },
    isImage(filename) {
      const ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
      case 'jpg':
      case 'bmp':
      case 'png':
        return true;
      }
      return false;
    },
    uploadImage(event) {
      this.reset();
      const input = event.target;

      if (input.files && input.files[0]) {
        if (this.isImage(input.files[0].name)) {
          this.isImageFlag = true;

          if (input.files[0].size / 1000 < 2100) {
            const reader = new FileReader();

            this.isSizeFlag = true;

            reader.onload = e => {
              this.originalCropImg = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            this.showModal('modal');
          } else {
            this.isSizeFlag = false;
            this.$modal.hide('modal');
          }
        } else {
          this.isImageFlag = false;
          this.$modal.hide('modal');
        }
      }
    },
    crop() {
      const {canvas, coordinates} = this.$refs.cropper.getResult();

      this.croppingImg = canvas.toDataURL();
      this.getCropCoords(coordinates);

      this.hideModal('modal');
    },
    sendCrop() {
      return new Promise(resolve => {
        const formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);

        this.$store.dispatch('overall/POST_CROPPER', formData).then(response => {
          this.getCropHash(response.data.id);
          resolve();
        });
      });
    },
    sendAdminCrop() {
      return new Promise(resolve => {
        const formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
        this.$store.dispatch('admin/POST_CROPPER', formData).then(response => {
          this.getCropHash(response.data.id);
          resolve();
        });
      });
    },
    triggerFileClick() {
      this.$refs.file.click();
      this.$emit('clearPhoto');
    }
  }
};
</script>
